.dataTables_wrapper .dataTables_length label select {
  margin: 0 6px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .22rem center;
  background-size: 14px 10px;
  padding-right: 20px;
  text-align-last: center;
  -moz-text-align-last: center;
}

.dataTables_wrapper .dataTables_empty {
  text-align: center !important;
  color: red;
  /* padding: 50px 10px !important; */
  opacity: 0.75;
}

@media (max-width: 991.90px) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dt-custom-filter {
    margin-bottom: 8px;
  }

  .dataTables_wrapper .dataTables_length {
    float: left;
  }

  .dataTables_wrapper .dataTables_filter label {
    max-width: 100%;
  }
}

.dataTables_processing::before {
  content: '';
  position: absolute;
  border-radius: 20px;
  top: 2px;
  left: 0;
  background: linear-gradient(to right, #edf2f9, #6477ba, #576fc7, #324daa) !important;
  width: 0;
  height: 3px;
  z-index: 9999;
  box-shadow: 0 0px 0 1px #fff;
  animation: tableloadingBar 4s linear infinite;
}

@keyframes tableloadingBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.dt-buttons {
  position: relative;
  left:5px;
  padding-right: 5px;
}

.dt-button-collection {
  position: absolute;
  width: 200px;
  top: 40px;
  left:auto !important;
  right: 0px !important;
  margin-top: 0 !important;
  background: #fff;
  box-shadow: 0 5px 14px #ccc;
  z-index: 10;
  padding: 15px;
  border-radius: 4px;
}

.dt-button-collection .dt-button {
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 15px;
  margin-bottom: 5px;
  font-size: 14px;
}

.dt-button-collection .dt-button.active,
.export-btn:hover {
  background: var(--blue);
  color: #fff;
  border: 1px solid var(--blue);
}

.dt-button-collection .dt-button:last-child {
  margin-bottom: 0;
}

.dt-button-collection-title {
  color: var(--bs-primary);
  font-size: 13px;
  text-align: left;
  margin-bottom: 8px;
}

table.dt-rowReorder-float {
  opacity: .1
}

div.dt-rowReorder-float-parent {
  table-layout: fixed;
  /* outline:2px solid #00000045; */
  outline-offset: -2px;
  z-index: 2001;
  position: absolute !important;
  overflow: hidden
}

tr.dt-rowReorder-moving {
  outline: 2px solid #324daa;
  outline-offset: -2px
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move
}

/* .dataTable tr td.drag {
  background-color: #576fc71f;
} */

.dataTable tr td.drag:hover {
  cursor: move;
}

/*End | Datatable layout modified*/
.dt-button.buttons-columnVisibility.dt-button-active {
  background-color:var(--blue);
  color: #fff;
}